<script>
  export let visible = false;
  export let width = '75%';

  function close() {
    visible = false;
  }
</script>

<div class="swallow-dialog__wrapper" class:is_visible={!visible} on:click|stopPropagation ={close}>
  <div class="swallow-dialog" style="--width: {width}" on:click|stopPropagation = {() => {}}>
    <div class="swallow-dialog__header">
      <!--            <div class="swallow-dialog__title"></div>-->
      <slot name="title"></slot>
    </div>
    <div class="swallow-dialog__body">
      <slot></slot>
    </div>
    {#if $$slots.footer}
    <div class="swallow-dialog__footer">
      <slot name="footer"></slot>
    </div>
    {/if}
  </div>
</div>

<style>
    .swallow-dialog__wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        z-index: 2001;
        background-color: rgb(0, 0, 0, .5);
    }
    .swallow-dialog {
        position: relative;
        margin: 15vh auto 50px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
        box-sizing: border-box;
        width: var(--width);
    }
    .swallow-dialog__body {
        padding: 20px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }
    .swallow-dialog__footer {
        padding: 10px 20px 20px;
        text-align: right;
        box-sizing: border-box;
    }
    .is_visible {
        display: none;
    }

</style>
