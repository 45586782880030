<script>
  import SwQrcode from '../../components/Qrcode/SwQrcode.svelte';
  import SwDialog from "../../components/dialog/SwDialog.svelte";
  import ServiceTerms from "../ServiceTerms/App.svelte";

  const iosPath = 'https://apps.apple.com/cn/app/%E8%B6%A3%E6%89%B9%E5%8F%91/id1505972566';
  const androidPath = 'https://sj.qq.com/myapp/detail.htm?apkName=com.gocountryside.nc&info=99A26B9646C86E8C352E256D10A5BC1';
  const qrcodeWidth = 200;

  let isShowImage = false;
  let dialogImage = "";

</script>

<section id='app-slider' class='section'>
  <div class='container'>
    <div class='row'>
      <div class='section-title center'>
        <h2>我们的 <span>产品</span></h2>
      </div>
    </div>
  </div>
  <div class="scroll-container2 container">
    <div class="image-grid container">
      <img src="/public/static/images/app1.jpg" alt="Image 1" on:click={() => {dialogImage = '/public/static/images/app1.jpg';
    isShowImage = true;}}>
      <img src="/public/static/images/app2.jpg" alt="Image 2" on:click={() => {dialogImage = '/public/static/images/app2.jpg';
    isShowImage = true;}}>
      <img src="/public/static/images/app3.jpg" alt="Image 3" on:click={() => {dialogImage = '/public/static/images/app3.jpg';
    isShowImage = true;}}>
      <img src="/public/static/images/app4.jpg" alt="Image 4" on:click={() => {dialogImage = '/public/static/images/app4.jpg';
    isShowImage = true;}}>
      <img src="/public/static/images/app5.jpg" alt="Image 5" on:click={() => {dialogImage = '/public/static/images/app5.jpg';
    isShowImage = true;}}>
    </div>
  </div>

</section>
<!--/ End Slider -->

<!-- Start blog -->
<section id='qrcode' class='section'>
  <div class='container'>
    <div class='row'>
      <div class='col-md-6 col-sm-12 col-xs-12' style='text-align: center'>
        <div style='width: 210px;margin: 0 auto'>
          <a target='_blank' href='{androidPath}'>
            <img src='/public/static/images/Android_down.png' alt='下载安卓app'>
          </a>
          <SwQrcode width='{qrcodeWidth}' value={androidPath} />
        </div>
      </div>

      <div class='col-md-6 col-sm-12 col-xs-12' style='text-align: center'>
        <div style='width: 210px;margin: 0 auto'>
          <a target='_blank' href='{iosPath}'><img src='/public/static/images/ios_down.png' alt='下载IOS应用'
                                                   style='margin-bottom: 25px'></a>
          <SwQrcode width='{qrcodeWidth}' value={iosPath} />
        </div>
      </div>
      <!--            <div class="col-md-1 col-sm-1 col-xs-1"></div>-->
      <!--            <div class="col-md-5 col-sm-10 col-xs-10 center">-->
      <!--                <a target="_blank" href="{androidPath}"><img src="/public/static/images/Android_down.png" alt="下载安卓app" style="margin-bottom:25px"></a>-->
      <!--                <SwQrcode width="{qrcodeWidth}" value={androidPath}/>-->
      <!--            </div>-->
      <!--            <div class="col-md-1 col-sm-1 col-xs-1"></div>-->
      <!--            <div class="col-md-5 col-sm-10 col-xs-10" style='float: right'>-->
      <!--                <a target="_blank" href="{iosPath}"><img src="/public/static/images/ios_down.png" alt="下载IOS应用" style="margin-bottom: 25px"></a>-->
      <!--                <SwQrcode width="{qrcodeWidth}" value={iosPath}/>-->
      <!--            </div>-->
    </div>
  </div>
</section>
<!-- Start blog -->

<!-- Start Testimonials -->
<section id='testimonial' class='section wow fadeIn'>
  <div class='container'>
    <div class='row'>
      <div class='col-md-12 col-sm-12 col-xs-12 wow fadeIn'>
        <div class='section-title center'>
          <h2>趣批发 <span>APP</span></h2>
        </div>
      </div>
    </div>
    <div class='row'>
      <div class='col-md-12 col-sm-12 col-xs-12'>

        <div class='testimonial-content'><p> 趣批发平台依托移动互联网技术，开发运营具有多重功能的手机APP，形成系统的移动电商平台，
          功能包括：供销发布、交易撮合、精准对接、线上线下支付、代理、实力认证等。 </p></div>
      </div>
    </div>
  </div>

  <!-- 放大后的图片容器 -->
  <SwDialog bind:visible={isShowImage} width="55%">
    <div slot="title" style="font-size: 18px;text-align: right;padding-right: 10px">
      <i class="fa fa-close" on:click={() => isShowImage = false}></i>
    </div>
    <div style="height: 70vh;overflow-y: auto">
      <img src="{dialogImage}" alt="">
    </div>
  </SwDialog>
</section>
<!--/ End Testimonial -->

<style>
    #qrcode img {
        display: inline-block;
        margin-bottom: 20px
    }

    .app-single-slider {
        border-radius: 100px;
        margin: 0 40px;
    }

    .scroll-container2 {
      width: 100%;
      overflow-x: auto; /* 允许横向滚动 */
      white-space: nowrap; /* 使所有图片在一行显示 */
    }

    .image-grid img {
      width: 200px; /* 设置图片宽度 */
      height: auto; /* 保持图片比例 */
      display: inline-block; /* 使图片横向排列 */
      margin-right: 30px; /* 图片间距 */
    }


</style>
