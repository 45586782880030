<!-- Start Slider -->
<section id="j-slider">
    <div class="slide-main">
        <!-- Single Slider -->
        <div class="single-slider" style="background-image:url(/public/static/images/slider/slider-bg4.jpg);" >
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-sm-12 col-xs-12">
                        <!-- Slider Text -->
                        <div class="slide-text left">
                            <div class="slider-inner">
                                <h1><span>我们提供了</span>广阔的平台</h1>
                                <p>趣批发平台依托移动互联网技术，开发运营具有多重功能的手机APP，形成系统的移动电商平台</p>
                            </div>
                        </div>
                        <!--/ End Slider Text -->
                    </div>
                </div>
            </div>
        </div>
        <!--/ End Single Slider -->
        <!-- Single Slider -->
        <div class="single-slider" style="background-image:url(/public/static/images/slider/slider-bg4.jpg);" >
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-sm-12 col-xs-12">
                        <!-- Slider Text -->
                        <div class="slide-text left">
                            <div class="slider-inner">
                                <h1><span>我们创建了</span>很棒的网站</h1>
                                <p>稻趣网络科技有限公司是一家基于移动互联网技术、深耕农产品产地基地、提供农业产业链服务、提升农业生产水平和促进农产品高效流通的互联网公司。</p>
                            </div>
                        </div>
                        <!--/ End Slider Text -->
                    </div>
                </div>
            </div>
        </div>
        <!--/ End Single Slider -->
        <!-- Single Slider -->
        <div class="single-slider" style="background-image:url(/public/static/images/slider/slider-bg4.jpg);" >
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-sm-12 col-xs-12">
                        <!-- Slider Text -->
                        <div class="slide-text left">
                            <div class="slider-inner">
                                <h1><span>我们打造了</span>优质的服务</h1>
                                <p>稻趣网络科技有限公司为商户，代理交易提供安全的信息化处理系统</p>
                            </div>
                        </div>
                        <!--/ End Slider Text -->
                    </div>
                </div>
            </div>
        </div>
        <!--/ End Single Slider -->
    </div>
</section>
<!--/ End Slider -->

<!-- Start Service -->
<section id="service" class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 wow fadeIn">
                <div class="section-title center">
                    <h2>我们的 <span>服务</span></h2>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- Single Service -->
<!--            <div class="col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-delay="0.4s">-->
<!--                <div class="single-service">-->
<!--                    <i class="fa fa-check"></i>-->
<!--                    <h2>交易撮合</h2>-->
<!--                    <p>专业的交易业务员</p>-->
<!--                </div>-->
<!--            </div>-->
            <!--/ End Single Service -->
            <!-- Single Service -->
<!--            <div class="col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-delay="0.6s">-->
<!--                <div class="single-service">-->
<!--                    <i class="fa fa-edit"></i>-->
<!--                    <h2>实力认证</h2>-->
<!--                    <p>多方认证，实力保证</p>-->
<!--                </div>-->
<!--            </div>-->
            <!--/ End Single Service -->
            <!-- Single Service -->
            <div class="col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-delay="0.8s">
                <div class="single-service">
                    <i class="fa fa-map-signs"></i>
                    <h2>精准对接</h2>
                    <p>客户信息准确，精确对接</p>
                </div>
            </div>
            <!--/ End Single Service -->
            <!-- Single Service -->
            <div class="col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-delay="1s">
                <div class="single-service">
                    <i class="fa fa-send"></i>
                    <h2>供销分布</h2>
                    <p>精确定位，供销清晰，分布广泛</p>
                </div>
            </div>
            <!--/ End Single Service -->
            <!-- Single Service -->
            <div class="col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-delay="1s">
                <div class="single-service">
                    <i class="fa fa-money"></i>
                    <h2>线上线下支付</h2>
                    <p>多种交易方式，提供安全交易环境</p>
                </div>
            </div>
            <!--/ End Single Service -->
            <!-- Single Service -->
            <div class="col-md-3 col-sm-6 col-xs-12 wow fadeIn" data-wow-delay="1s">
                <div class="single-service">
                    <i class="fa fa-handshake-o"></i>
                    <h2>代理</h2>
                    <p>通过平台交易，为代理提供丰厚的收益</p>
                </div>
            </div>
            <!--/ End Single Service -->
        </div>
    </div>
</section>
<!--/ End Service -->
