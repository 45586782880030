<script>
    import MenuTop from "./MenuTop.svelte";
    import Profile from "./Profile.svelte";
    import AppDownload from "./AppDownload.svelte";
    import Contact from "./Contact.svelte";
    import Proxy from "./Proxy.svelte";
    import News from "./News.svelte";
    import Foot from "./Foot.svelte";
    import AboutUs from './AboutUs.svelte';

    const options = [
        { label: '首页',   target: 'j-slider' },
        { label: '产品', target: 'app-slider' },
        { label: '关于稻趣',  target: 'about-us'  },
        { label: '联系我们',  target: 'location'  }
    ];
    let selected = options[0];
</script>

<MenuTop bind:activeMenu={selected} options={options}/>
<!--<svelte:component this={selected.component}/>-->
<Profile/>
<AppDownload/>
<AboutUs/>
<Contact/>
<Foot/>
