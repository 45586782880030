<section class='section' id='about-us'>
  <div class='container'>
    <div class='row'>
      <div class='col-md-12 col-sm-12 col-xs-12 wow fadeIn' style='visibility: visible; animation-name: fadeIn;'>
        <div class='section-title center'>
          <h2>发展历程</h2>

        </div>
      </div>
    </div>
    <div class='timeline-small' style='font-size: 22px;'>
      <div class='timeline-small-body'>
        <ul>
          <li>
            <div class='bullet pink'></div>
            <div class='date'>2018-12-26</div>
            <div class='desc'>

              <p>公司成立</p>

            </div>
          </li>
          <li>
            <div class='bullet orange'></div>
            <div class='date'>2019-1-22</div>
            <div class='desc'>
              <p>趣批发移动端应用软件—趣批发APP上线</p>

            </div>
          </li>
          <li>
            <div class='bullet blue'></div>
            <div class='date'>2019-2-21</div>
            <div class='desc'>
              <p>趣批发开始布局微信生态小程序矩阵的研发</p>

            </div>
          </li>

          <li>
            <div class='bullet green'></div>
            <div class='date'>2019-3-30</div>
            <div class='desc'>
              <p>趣批发产品买卖在线聊天系统上线</p>

            </div>
          </li>
          <li>
            <div class='bullet green'></div>
            <div class='date'>2019-3-05</div>
            <div class='desc'>
              <p>趣批发实现智能推荐算法推荐商品</p>

            </div>
          </li>
          <li>
            <div class='bullet green'></div>
            <div class='date'>2019-4-05</div>
            <div class='desc'>
              <p>趣批发代理模式上线</p>

            </div>
          </li>
          <li>
            <div class='bullet green'></div>
            <div class='date'>2019-5-13</div>
            <div class='desc'>
              <p>趣批发全新ui上线</p>

            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<style>
    .desc {
        font-size: 17px;
    }

    .timeline-small {
        max-height: 750px;
        overflow: hidden;
        margin: 30px auto 0;
        border-left: 3px #16A085;
        font-family: 'Open Sans', sans-serif;
    }

    .timeline-small-body ul {
        padding: 1em 0 0 2em;
        margin: 0;
        list-style: none;
        position: relative;
    }

    .timeline-small-body ul::before {
        content: ' ';
        height: 100%;
        width: 2px;
        background-color: #16A085;
        position: absolute;
        top: 0;
        left: 2.4em;
    }

    .timeline-small-body li div {
        display: inline-block;
        margin: 1em 0;
        vertical-align: top;
    }

    .timeline-small-body .bullet {
        width: 1rem;
        height: 1rem;
        box-sizing: border-box;
        z-index: 1;
        margin-right: 1rem;
        margin-top: 7%;
    }

    .timeline-small-body .date {
        width: 23%;
        font-size: 0.75em;
        padding-top: 0.40rem;
        padding-right: 2rem;
        font-size: 15px;
    }

    .timeline-small-body .desc {
        width: 50%;
    }

    .timeline-small-body h3 {
        font-size: 0.9em;
        font-weight: 400;
        margin: 0;
    }

    .timeline-small-body h4 {
        margin: 0;
        font-size: 0.7em;
        font-weight: 400;
        color: #808080;
    }

</style>
