<!-- Start Breadcrumbs -->
<section id="breadcrumbs">
    <div class="container">
        <div class="row">
            <div class="col-md-10">
                <h2>投资代理</h2>
                <ul>
                    <li><a href="index.html"><i class="fa fa-home"></i></a></li>
                    <li class="active"><a href="#">代理优势</a></li>
                </ul>
            </div>
<!--            <div class="col-md-2">-->
<!--                <a href="#" class="button primary">联系我们</a>-->
<!--            </div>-->
        </div>
    </div>
</section>
<!--/ End Breadcrumbs -->

<!-- Start Project -->
<section id="portfolio" class="section archive page">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- Single Portfolio -->
                <div class="portfolio-single">
                    <a href="images/portfolio/6.jpg" class="zoom">
                        <div class="portfolio-head">
                            <img src="images/portfolio/6.jpg" alt=""/>
                            <i class="fa fa-search"></i>
                        </div>
                    </a>
                    <div class="text">
                        <h4><a>获得期权</a></h4>
                        <p>成为代理将获得期权</p>
                    </div>
                </div>
                <!--/ End Portfolio -->
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- Single Portfolio -->
                <div class="portfolio-single">
                    <a href="images/portfolio/2.jpg" class="zoom">
                        <div class="portfolio-head">
                            <img src="images/portfolio/2.jpg" alt=""/>
                            <i class="fa fa-search"></i>
                        </div>
                    </a>
                    <div class="text">
                        <h4><a>获取批发资源</a></h4>
                        <p>成为代理能够获取更多批发资源</p>
                    </div>
                </div>
                <!--/ End Portfolio -->
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- Single Portfolio -->
                <div class="portfolio-single">
                    <a href="images/portfolio/3.jpg" class="zoom">
                        <div class="portfolio-head">
                            <img src="images/portfolio/3.jpg" alt=""/>
                            <i class="fa fa-search"></i>
                        </div>
                    </a>
                    <div class="text">
                        <h4><a>获得抽成</a></h4>
                        <p>成为代理能从贸易中获得抽成</p>
                    </div>
                </div>
                <!--/ End Portfolio -->
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- Single Portfolio -->
                <div class="portfolio-single">
                    <a href="images/portfolio/4.jpg" class="zoom">
                        <div class="portfolio-head">
                            <img src="images/portfolio/4.jpg" alt=""/>
                            <i class="fa fa-search"></i>
                        </div>
                    </a>
                    <div class="text">
                        <h4><a>获得推送</a></h4>
                        <p>成为代理我们会提供推送服务，让你及时获得信息</p>
                    </div>
                </div>
                <!--/ End Portfolio -->
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- Single Portfolio -->
                <div class="portfolio-single">
                    <a href="images/portfolio/5.jpg" class="zoom">
                        <div class="portfolio-head">
                            <img src="images/portfolio/5.jpg" alt=""/>
                            <i class="fa fa-search"></i>
                        </div>
                    </a>
                    <div class="text">
                        <h4><a>置顶</a></h4>
                        <p>成为代理我们提供置顶服务</p>
                    </div>
                </div>
                <!--/ End Portfolio -->
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
                <!-- Single Portfolio -->
                <div class="portfolio-single">
                    <a href="images/portfolio/6.jpg" class="zoom">
                        <div class="portfolio-head">
                            <img src="images/portfolio/6.jpg" alt=""/>
                            <i class="fa fa-search"></i>
                        </div>
                    </a>
                    <div class="text">
                        <h4><a>做广告</a></h4>
                        <p>成为代理我们为您提供广告位</p>
                    </div>
                </div>
                <!--/ End Portfolio -->
            </div>
        </div>
    </div>
    <div class="gmap">
        <div class="map"></div>
    </div>
</section>
<!--/ End Project -->

<!-- 展示商户、代理的商标 -->
<div id="clients">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="clients-carousel">
                    <!-- Single Clients -->
                    <div class="single-client">
                        <img src="WEB-INF/images/client1.png" alt="" class="img-responsive">
                    </div>
                    <!--/ Single Clients -->
                    <!-- Single Clients -->
                    <div class="single-client">
                        <img src="WEB-INF/images/client2.png" alt="" class="img-responsive">
                    </div>
                    <!--/ Single Clients -->
                    <!-- Single Clients -->
                    <div class="single-client">
                        <img src="WEB-INF/images/client3.png" alt="" class="img-responsive">
                    </div>
                    <!--/ Single Clients -->
                    <!-- Single Clients -->
                    <div class="single-client">
                        <img src="WEB-INF/images/client4.png" alt="" class="img-responsive">
                    </div>
                    <!--/ Single Clients -->
                    <!-- Single Clients -->
                    <div class="single-client">
                        <img src="WEB-INF/images/client5.png" alt="" class="img-responsive">
                    </div>
                    <!--/ Single Clients -->
                    <!-- Single Clients -->
                    <div class="single-client">
                        <img src="WEB-INF/images/client6.png" alt="" class="img-responsive">
                    </div>
                    <!--/ Single Clients -->
                </div>
            </div>
        </div>
    </div>
</div>
<!--/ End Clients -->
