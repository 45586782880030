<div>
    <!-- Location -->
    <section id="location" class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 wow fadeIn">
                    <div class="section-title center">
                        <h2>我们的 <span>联系方式</span></h2>
                        <p>稻趣网络科技有限公司是一家基于移动互联网技术、深耕农产品产地基地、提供农业产业链服务、提升农业生产水平和促进农产品高效流通的互联网公司。</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 wow fadeIn" data-wow-delay="0.4s">
                    <!-- Single Address -->
                    <div class="single-address">
                        <i class="fa fa-phone"></i>
                        <h4>公司电话</h4>
                        <p>18559629468</p>
                    </div>
                    <!--/ End Single Address -->
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 wow fadeIn" data-wow-delay="0.6s">
                    <!-- Single Address -->
                    <div class="single-address">
                        <i class="fa fa-map-marker"></i>
                        <h4>办公地点</h4>
                        <p>厦门火炬高新区创业园创业大厦创客汇-91</p>
                    </div>
                    <!--/ End Single Address -->
                </div>
                <div class="col-md-4 col-sm-4 col-xs-12 wow fadeIn" data-wow-delay="0.8s">
                    <!-- Single Address -->
                    <div class="single-address">
                        <i class="fa fa-envelope-o"></i>
                        <h4>公司邮箱</h4>
                        <p>qupifa@qinongcun.com</p>
                    </div>
                    <!--/ End Single Address -->
                </div>

            </div>
        </div>
    </section>
</div>
