<script>
    /**
     * 官网首页
     */
    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher();
    export let activeMenu = '';
    export let options = [];
    function active(target) {
        activeMenu = target;
        dispatch('active', target);
    }
</script>

<header id="header">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-12 col-xs-12">
                <div class="logo">
                    <a href="#">
                        <img style="display: inline;width: 23px;margin-bottom: 5px" src="public/static/pifa-logo.png">
                    </a>
                    <a href="#">趣批发</a>
                    <a href="#" style="font-size: 20px;">-农产品批发平台</a>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
                <div class="nav-area">
                    <nav class="mainmenu">
                        <div class="mobile-nav"></div>
                        <div class="collapse navbar-collapse">
                            <ul class="nav navbar-nav menu">
                                {#each options as { label, target }, i}
                                    <li><a href={`#${target}`}>{label}</a></li>
                                {/each}
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>
