<footer id="footer" class="wow fadeIn">
    <!-- Footer Top -->
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 ">
                    <!-- Logo -->
                    <div class="logo">
                        <a href="#j-slider"><span>趣批发</span></a>
                    </div>
                    <div style="font-size: 12px;color: blue">
                        <a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备19013495号-1</a>
                    </div>
                    <!--/ End Logo -->
                </div>
            </div>
        </div>
    </div>
    <!--/ End Footer Top -->

    <!-- Copyright -->
    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12">
                    <div class="text">
                        <p>&copy; Copyright 2018</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/ End Copyright -->
</footer>
