<div class="xy_head">服务条款</div>
<div class="xy_body">
    欢迎阅读稻趣公司服务条款协议（下称“本协议”）。“稻趣公司农产品信息商务平台”（以下简称“趣批发平台”）是由稻趣网络科技（厦门）有限公司（以下简称“稻趣公司”）运营的、为农产品经营双方提供全面的信息服务等增值服务（以下简称“服务”）的互联网平台。
    <div class="xy_bodyHead">第一条&nbsp;&nbsp;接受条款</div>
    1.1您知悉，使用稻趣公司服务以同意本协议并完成全部注册程序为前提。点击同意即表示您同意自己与稻趣公司订立本协议，且您将受本协议的条款（以下简称“条款”）及趣批发平台公示的各项规则、规范之约束。稻趣公司可根据业务发展需要随时更改“条款”，并将修改后的服务条款协议公示在趣批发平台上，通知予您。经修订的服务条款协议一经公示，立即自动生效。如您不同意相关变更，可停止使用“服务”。您继续使用“服务”将表示您接受经修订的“条款”。除另行明确声明外，任何使“服务”范围扩大或功能增强的新内容均受本协议约束。
    <div class="xy_bodyHead">第二条&nbsp;&nbsp;注册</div>
    2.1“服务”仅供根据相关法律能够订立具有法律约束力的合约的个人或组织使用。同时，稻趣公司可根据业务需要拒绝、中止或终止向某些个人或组织提供“服务”。
    <br>2.2在注册成为稻趣公司用户时，您同意：
    <br>（1）提供关于您或贵单位的真实、准确、完整和反映当前情况的资料；
    <br>（2）维持并及时更新您的资料，使其保持真实、准确、完整和反映当前情况。倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或稻趣公司有合理理由怀疑该等资料不真实、不准确、不完整或不能反映当前情况，稻趣公司有权暂停或终止您的注册身份及资料，并拒绝您在目前或将来对“服务”(或其任何部份) 以任何形式使用。如您代表一家公司或其他法律主体在稻趣公司注册，则您声明和保证，您有权使该公司或其他法律主体受本协议“条款”约束。
    <br>2.3您须自行保管好您的用户名和密码，且须对在您用户注册名和密码下发生的所有活动承担责任。您同意：(a) 如发现任何人未经授权使用您的用户注册名或密码，或发生违反保密规定的任何其他情况，您会立即通知稻趣公司；及 (b) 确保您在每个上网时段结束时，以正确步骤离开。稻趣公司不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。
    <div class="xy_bodyHead">第三条&nbsp;&nbsp;服务内容</div>
    3.1注册成为趣批发平台用户完全免费。稻趣公司将根据自身业务情况向您提供付费服务，但会提前通知并征得您的同意。
    <br>3.2您可通过稻趣公司物色交易对象，就货物和服务的交易进行协商，以及获取各类与贸易相关的信息。但是，稻趣公司不能控制交易所涉及的物品的质量、规格、安全性或合法性，亦不以任何形式对商贸信息的真实性或准确性、交易对方的履约能力及履约情况做任何形式的承诺或担保。
    <br>3.3您因进行交易、向稻趣公司获取有偿服务或接触稻趣公司服务器而发生的所有应纳税赋，以及一切硬件、软件、服务及其他方面的费用均由您负责支付。稻趣公司保留在无须发出通知的情况下，暂时或永久地更改或停止部份或全部“服务”的权利。
    <div class="xy_bodyHead">第四条&nbsp;&nbsp;趣批发平台仅作为交易地点</div>
    4.1趣批发平台仅作为用户物色交易对象，就货物和服务的交易进行协商，以及获取各类与贸易相关的服务的地点。稻趣公司不能也不会控制交易各方能否履行协议义务。此外，您应注意到，与外国国民、未成年人或以欺诈手段行事的人进行交易的风险是客观存在的。
    <div class="xy_bodyHead">第五条&nbsp;&nbsp;使用规则</div>
    5.1“您的资料”指您在注册、交易或展示物品过程中、在任何公开信息场合或通过电子邮件和/或任何其他形式，向稻趣公司或其他用户提供的、展示的任何资料，包括但不限于数据、文字、软件、音乐、声响、照片、图画、影像等。您应向稻趣公司及其他用户提交真实、准确、合法的信息，并对“您的资料”负全部责任。您特此保证，您对提交给稻趣公司的“您的资料”拥有全部权利，包括全部版权。您确认，稻趣公司没有责任去认定或决定您提交的资料哪些是应当受到保护的，对享有“服务”的其他用户使用“您的资料”，稻趣公司也不必负责。倘若稻趣公司认为“您的资料”存在任何违犯法律、法规、行政规章的情形，或可能使稻趣公司陷于任何纠纷或诉讼时，稻趣公司可在不经过您事先同意的前提下自行对“您的资料”采取必要或适当的任何行动，包括但不限于下线或删除该类资料。
    <br>5.2您同意，“您的资料”和您提供在趣批发平台上交易的任何“物品”（泛指一切可供依法交易的、有形的或无形的、以各种形态存在的某种具体的物品，或某种权利或利益，或某种票据或证券，或某种服务或行为。本协议中“物品”一词均含此义）：
    <br>（1）不会有欺诈成份，与售卖伪造或盗窃无涉；
    <br>（2）不会侵犯任何第三者对该物品享有的合法权利，包括但不限于物权、知识产权、隐私权、名誉权等；
    <br>（3）不会违反任何法律、法规、条例或规章 (包括但不限于关于规范出口管理、贸易配额、保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)；不得上传、展示或传播任何不实虚假、冒充性的、骚扰性的、中伤性的、攻击性的、辱骂性的、恐吓性的、种族歧视性的、诽谤诋毁、泄露隐私、成人情色、恶意抄袭的或其他任何非法的信息资料；
    <br>（4）不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序；
    <br>（5）不会直接或间接与下述各项货物或服务连接，或包含对下述各项货物或服务的描述：(i) 法律法规或趣批发平台的规定禁止交易的货物或服务；或 (ii) 您无权连接或包含的货物或服务。
    <br>5.3您在使用服务过程中，必须遵循以下原则：
    <br>（1）不得以任何形式侵犯稻趣公司的权利和/或利益或作出任何不利于稻趣公司的行为；
    <br>（2）不得违反中华人民共和国法律法规及相关国际条约或规则；
    <br>（3）不得违反与网络服务、稻趣公司服务有关的网络协议、规定、程序及行业规则；
    <br>（4）不得违反法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求；
    <br>（5）不得进行任何可能对互联网或移动网正常运转造成不利影响的行为；
    <br>（6）不得以任何方式侵犯其他任何人依法享有的专利权、著作权、商标权等知识产权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等人身权益，或其他任何合法权益；
    <br>（7）不得利用帮助实现蒙蔽或欺骗意图的同伙(下属的客户或第三方)，操纵与另一交易方所进行的商业谈判的结果；
    <br>（8）不得使用任何装置、软件或例行程序干预或试图干预稻趣公司的正常运作或正在稻趣公司上进行的任何交易。您不得采取对任何将不合理或不合比例的庞大负载加诸稻趣公司网络结构的行动；
    <br>（9）不得为其他任何非法目的而使用服务。
    <br>5.4被禁止物品。
    <br>您不得在趣批发平台公布或通过趣批发平台买卖：
    <br>（1）可能使稻趣公司违反任何相关法律、法规、条例或规章的任何物品；
    <br>（2）目前列入趣批发平台被禁止物品清单的任何物品。趣批发平台被禁止物品清单经在此提及而被纳入本协议，并可随时加以更新。
    <div class="xy_bodyHead">第六条&nbsp;&nbsp;您授予稻趣公司的许可使用权</div>
    6.1您授予稻趣公司独家的、全球通用的、永久的、免费的许可使用权利 (并有权在多个层面对上述授权进行再授权)，使稻趣公司有权(全部或部份地) 使用、复制、修订、改写、发布、翻译、分发、执行和展示"您的资料"或制作其衍生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将"您的资料"纳入其他作品内。
    <div class="xy_bodyHead">第七条&nbsp;&nbsp;隐私</div>
    7.1保护您的隐私是稻趣公司的一项基本原则，为此稻趣公司将基于保护用户隐私这个前提，合法使用“您的资料”。请注意，您一旦自愿地在稻趣公司交易地点披露“您的资料”，该等资料即可能被其他人士获取和使用。
    <br>7.2为提升服务的质量，稻趣公司可能会与第三方合作共同向用户提供相关的服务，此类合作可能需要包括但不限于用户数据与第三方用户数据的互通。在此情况下，您知晓并同意：如该第三方同意承担与稻趣公司同等的保护用户隐私的责任，则稻趣公司有权将用户的注册资料等提供给该第三方，并与第三方约定用户数据仅为双方合作的稻趣公司服务之目的使用；并且，稻趣公司将对该等第三方使用用户数据的行为进行监督和管理，尽一切合理努力保护用户个人信息的安全性。
    <div class="xy_bodyHead">第八条&nbsp;&nbsp;交易程序</div>
    8.1 添加产品描述条目。
    <br>产品描述是由您提供的在趣批发平台上展示的文字描述、图画和/或照片，可以是：
    <br>（1）对您拥有而您希望出售的产品的描述；
    <br>（2）对您正寻找的产品的描述。您可在趣批发平台发布任一类产品描述，或两种类型同时发布，条件是，您必须将该等产品描述归入正确的类目内。趣批发平台不对产品描述的准确性或内容负责。
    <br>8.2 就交易进行协商。
    <br>交易各方通过在趣批发平台上明确描述报盘和回盘，进行相互协商。所有各方接纳报盘或回盘将使所涉及的趣批发平台用户有义务完成交易。除非在特殊情况下，诸如用户在您提出报盘后实质性地更改对物品的描述或澄清任何文字输入错误，或您未能证实交易所涉及的用户的身份等，报盘和承诺均不得撤回。
    <br>8.3 运用常识。
    <br>稻趣公司不能亦不试图对其他用户通过“服务”提供的资料进行控制。就其本质而言，其他用户的资料，可能是令人反感的、有害的或不准确的，且在某些情况下可能带有错误的标识说明或以欺诈方式加上标识说明。稻趣公司希望您在使用趣批发平台时，小心谨慎并运用常识。
    <div class="xy_bodyHead">第九条&nbsp;&nbsp;交易系统</div>
    9.1 不得操纵交易。
    <br>您同意不利用帮助实现蒙蔽或欺骗意图的同伙(下属的客户或第三方)，操纵与另一交易方所进行的商业谈判的结果。
    <br>9.2 系统完整性。
    <br>您同意，您不得使用任何装置、软件或例行程序干预或试图干预趣批发平台的正常运作或正在趣批发平台上进行的任何交易。您不得采取对任何将不合理或不合比例的庞大负载加诸稻趣公司网络结构的行动。您不得向任何第三者披露您的密码，或与任何第三者共用您的密码，或为任何未经批准的目的使用您的密码。
    <br>9.3 不作商业性利用。
    <br>您同意，您不得对任何资料作商业性利用，包括但不限于在未经趣批发平台授权高层管理人员事先书面批准的情况下，复制在趣批发平台上展示的任何资料并用于商业用途。
    <div class="xy_bodyHead">第十条&nbsp;&nbsp;中止或终止</div>
    10.1鉴于网络服务的特殊性，稻趣公司可能因系统维护或升级的需要而暂停或终止服务，此时稻趣公司无须承担任何责任，请您知悉，但此前将尽可能提前在网页通告。
    <br>10.2如发生下列任何一种情形，稻趣公司有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户，且不承担任何责任：
    <br>（1）用户提供的个人资料不真实；
    <br>（2）用户违反本协议中之约定；
    <br>（3）稻趣公司发现您从事涉及趣批发平台的诈骗活动；
    <br>（4）稻趣公司有理由认为您的行为可能会使您、趣批发平台用户或第三人发生任何法律责任。
    <br>10.3经生效法律文书确认用户存在违法或违反本协议行为或者稻趣公司自行判断用户涉嫌存在违法或违反本协议行为的，稻趣公司有权在趣批发平台上以网络发布形式公布用户的违法行为并做出处罚（包括但不限于限权、终止服务等）。
    <div class="xy_bodyHead">第十一条&nbsp;&nbsp;服务“按现状”提供</div>
    11.1稻趣公司会尽一切努力使您在使用趣批发平台的过程中得到乐趣。遗憾的是，稻趣公司不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。为此，您明确理解和同意，您使用“服务”的风险由您自行承担。“服务”以“按现状”和“按可得到”的基础提供。稻趣公司明确声明不作出任何种类的所有明示或暗示的保证，包括但不限于关于适销性、适用于某一特定用途和无侵权行为等方面的保证。稻趣公司对下述内容不作保证：(i)“服务”会符合您的要求；(ii)“服务”不会中断，且适时、安全和不带任何错误；(iii) 通过使用“服务”而可能获取的结果将是准确或可信赖的；及 (iv) 您通过“服务”而购买或获取的任何产品、服务、资料或其他材料的质量将符合您的预期。通过使用“服务”而下载或以其他形式获取任何材料是由您自行全权决定进行的，且与此有关的风险由您自行承担，对于因您下载任何该等材料而发生的您的电脑系统的任何损毁或任何数据损失，您将自行承担责任。您从趣批发平台或通过或从“服务”获取的任何口头或书面意见或资料，均不产生未在本协议内明确载明的任何保证。
    <div class="xy_bodyHead">第十二条&nbsp;&nbsp;责任范围</div>
    12.1稻趣公司并非交易主体，用户间因交易而产生的法律纠纷应由双方自行解决，与稻趣公司无涉。倘若您与一名或一名以上用户，或与您通过稻趣公司获取其服务的第三方产品和/服务供应商发生争议，您免除稻趣公司 (及稻趣公司代理人和雇员) 在因该等争议而引起的， 或在任何方面与该等争议有关的不同种类和性质的任何(实际和后果性的) 权利主张、要求和损害赔偿等方面的责任。
    <br>12.2您明确理解和同意，稻趣公司不对因下述任一情况而发生的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿 (无论稻趣公司是否已被告知该等损害赔偿的可能性)：(i) 使用或未能使用“服务”；(ii) 因通过或从“服务”购买或获取任何货物、样品、数据、资料或服务，或通过或从“服务”接收任何信息或缔结任何交易所产生的获取替代货物和服务的费用；(iii) 未经批准接入或更改您的传输资料或数据；(iv) 任何第三者对“服务”的声明或关于“服务”的行为；或 (v) 因任何原因而引起的与“服务”有关的任何其他事宜，包括疏忽。
    <div class="xy_bodyHead">第十三条&nbsp;&nbsp;赔偿</div>
    13.1您同意，因您违反本协议或经在此提及而纳入本协议的其他文件，或因您违反了法律或侵害了第三方的权利，而使第三方对稻趣公司及其子公司、分公司、董事、职员、代理人提出索赔要求（包括司法费用和其他专业人士的费用），您必须赔偿给稻趣公司及其子公司、分公司、董事、职员、代理人，使其等免遭损失。
    <div class="xy_bodyHead">第十四条&nbsp;&nbsp;遵守法律</div>
    14.1您应遵守与您使用“服务”，以及与您竞买、购买和销售任何物品以及提供商贸信息有关的所有相关的法律、法规、条例和规章。
    <div class="xy_bodyHead">第十五条&nbsp;&nbsp;无代理关系</div>
    15.1您与稻趣公司仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇佣与被雇佣或特许权授予与被授予关系。
    <div class="xy_bodyHead">第十六条&nbsp;&nbsp;广告</div>
    16.1您与在“服务”上或通过“服务”物色的刊登广告人士通讯或进行业务往来或参与其推广活动，包括就相关货物或服务付款和交付相关货物或服务，以及与该等业务往来相关的任何其他条款、条件、保证或声明，仅限于在您和该刊登广告人士之间发生。您同意，对于因任何该等业务往来或因在“服务”上出现该等刊登广告人士而发生的任何种类的任何损失或损毁，稻趣公司无需负责或承担任何责任。
    <div class="xy_bodyHead">第十七条&nbsp;&nbsp;链接</div>
    17.1“服务”或第三者均可提供与其他万维网网站或资源的链接。由于稻趣公司并不控制该等网站和资源，您承认并同意，稻趣公司并不对该等外在网站或资源的可用性负责，且不认可该等网站或资源上或可从该等网站或资源获取的任何内容、宣传、产品、服务或其他材料，也不对其等负责或承担任何责任。您进一步承认和同意，对于任何因使用或信赖从此类网站或资源上获取的此类内容、宣传、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，稻趣公司均不承担责任。
    <div class="xy_bodyHead">第十八条&nbsp;&nbsp;通知</div>
    18.1除非另有明确规定，稻趣公司的任何通知将以包括但不限于短信、电话、稻趣公司APP站内信、稻趣公司APP信息公告等方式予以送达，具体方式稻趣公司将视运营情况自行选择。
    <div class="xy_bodyHead">第十九条&nbsp;&nbsp;不可抗力</div>
    19.1不可抗力。不可抗力是指不能预见、不能避免并不能克服的客观情况，包括但不限于：战争、骚乱、洪水、地震、台风、国家公布的疫情等事件。因不可抗力致使协议不能正常履行的，无需承担违约责任。
    <br>19.2.平台因下列状况无法正常运作，使会员无法使用各项服务，稻趣公司不承担损害赔偿责任，该状况包括但不限于：
    <br>（1）稻趣公司提前公告系统停机维护期间；
    <br>（2）因停电、系统故障、传输线路、通信故障及通信线路等不可预测因素造成不能提供服务的；
    <br>（3）由于黑客攻击、电信部门调整或故障、网站升级、第三方方面的问题等原因而造成的服务中断、终止或者延迟。
    对于上述情形，稻趣公司应尽可能减少会员损失，并于发生该些情况后尽快通知您。
    <div class="xy_bodyHead">第二十条&nbsp;&nbsp;转让</div>
    20.1稻趣公司转让本协议无需经您同意。
    <div class="xy_bodyHead">第二十一条&nbsp;&nbsp;其他规定</div>
    21.1本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理，并以稻趣公司所在地人民法院（北京市海淀区或其更高一级法院）为第一审管辖法院。
    <br>21.2&nbsp;如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部分则仍具有法律效力。
    <br>21.3您同意稻趣公司有权根据业务需要对本协议和规则内容进行补充和（或）变更，并以在趣批发平台公告的方式予以公布，无需另行单独通知您；若您在本协议内容公告变更后继续使用本服务的，即表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用本服务；若您不同意修改后的协议内容，您应停止使用本服务，并向稻趣公司提交书面协议终止申请。
    <br>21.4稻趣公司其他规则、声明等信息均在趣批发平台载明，如需获取该等信息，请您前往趣批发平台“常见问题”查阅。
    <div class="xy_bodyHead">隐 私 声 明</div>
    稻趣公司手机APP（以下简称：趣批发平台）属稻趣公司公司运营（以下简称：“稻趣公司”或“我们”）所有，并由稻趣公司负责运营。在访问和使用趣批发平台前请务必阅读本声明并保证完全理解本声明所载明的一切条款，若您对条款存在异议可与稻趣公司客服联系。若您不认可本声明中的条款，您可以选择立即停止使用趣批发平台的所有服务。一旦您访问、使用了趣批发平台，将视为您同意并接受了本声明的所有内容。
    <br>一、未成年人的特别注意事项
    <br>若没有父母或监护人的同意，未成年人不得创建自己的用户账号。如您为未成年人的，建议您请您的父母或监护人仔细阅读本协议内容，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
    <br>对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    二、信息的收集
    <br>（一）注册信息
    <br>当您注册成为稻趣公司用户或使用稻趣公司服务时，您需要提供您的真实姓名，地址，身份信息，银行卡信息，电话号码，主营产品等信息，以便使我们能核实您是否有资格访问和使用趣批发平台的某些信息，资料或服务，便于我们提高您的平台访问效率，为您提供良好的客户服务和技术支持，对您进行跟踪回访。我们通过上述信息来获得注册用户的统计资料。我们将会用这些统计数据来给我们的用户分类，例如身份类型、从事行业等等，以便有针对性地向我们的用户提供新的服务和机会。
    <br>当您打算注册成用户时，您需要注册一个用户名和密码。您只能通过您的密码来使用您的帐号。您有义务保管好自己的密码，如果您泄漏了密码，您可能丢失了您的个人识别信息，并且可能出现对您不利的后果。因此，无论任何原因危及您的密码安全，您应该立即通过稻趣公司客服与我们取得联系。
    <br>（二）交易信息
    <br>我们跟踪IP地址是出于身份和交易真实性的识别、安全考虑和其它符合国家规定的要求。我们会跟踪全天的页面访问数据。全天页面访问数据被用来反映平台的流量，以使我们可以为未来的发展制定计划。因您交易行为而产生的其它数据，我们将会用这些统计数据来更有针对性地提供更好更多的服务和机会。
    <br>（三）信息自动收集
    <br>我们有可能自动接收并记录您的浏览器和计算机上的信息，其中包括您的 IP 地址、趣批发平台cookies 中的信息、软硬件特征信息，以及您需求的网页记录。cookies是少量的数据，在您未拒绝接受cookies的情况下，cookies将被发送到您的浏览器，并储存在您的计算机硬盘。我们使用cookies储存您访问我们平台的相关数据，在您访问或再次访问我们的平台时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。
    <br>您有权选择接受或拒绝接受cookies。您可以通过修改浏览器的设置以拒绝接受cookies，但是我们需要提醒您，因为您拒绝接受cookies，您可能无法使用依赖于cookies的我们平台的部分功能。
    <br>三、信息的共享、披露与转让
    <br>（一）我们不会出售或以其他方式共享您的个人信息，但以下情况除外：
    <br>1、事先获得您的同意或授权；
    <br>2、只有共享您的信息，才能实现我们的产品或服务的核心功能或提供您需要的服务；
    <br>3、应您需求，为您处理您与他人的纠纷或争议；
    <br>4、根据法律、法规的规定；
    <br>5、根据政府机关的要求；
    <br>6、基于学术研究而使用。
    <br>（二）我们不会将您的个人信息转让给任何公司、组织或个人，但以下情况除外：
    <br>1、事先获得您的同意或授权；
    <br>2、符合与您签署的相关协议、平台规则或其他的法律文件约定所提供；
    <br>3、根据适用的法律法规的要求、行政或司法机关要求所必须的情况进行提供；
    <br>4、在发生企业的合并、收购、资产转让或其他类似情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私条款的约束。
    <br>（三）用户信息的管理。
    <br>1、用户信息的查询、更正与补充。
    <br>（1）除法律、法规另有规定外，您可以通过以下方式自行访问您的个人信息：
    <br>账户信息——您可以登录帐号并通过“我的名片”编辑您在稻趣公司留存的个人信息；
    <br>搜索信息——您可以登录账号并通过首页搜索框访问或清除您的搜索历史记录。
    <br>订单信息：您可以登录账号并通过“我的订单”查阅或清除您的订单记录、交易记录等。
    <br>（2）如果您无法通过上述路径访问或修改其他个人信息，您可以通过稻趣公司客服与我们联系。我们会及时响应、处理。
    <br>2、用户信息的删除。
    <br>您可以登录帐号，在平台页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。在以下情形中，您可以向我们提出删除个人信息的请求：
    <br>（1）如果我们处理个人信息的行为违反法律法规；
    <br>（2）如果我们收集、使用您的个人信息，却未征得您的明确同意；
    <br>（3）如果我们处理个人信息的行为严重违反了与您的约定；
    <br>（4）如果您不再使用我们的产品或服务，或您主动注销了账号；
    <br>（5）如果我们永久不再为您提供产品或服务。
    若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
    当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    <br>3、账户注销。
    您可以联系稻趣公司客服申请注销账户。请您注意，当您成功申请注销账户后，我们将在您提出申请的2个工作日内完成对您的账户注销审核，审核通过后，我们将对您的个人信息进行删除或匿名化处理。除法律法规另有规定外，您注销账户后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息。
    <br>四、信息的存储和交换
    我们所收集的用户信息和资料将保存在稻趣公司或其关联公司的服务器上。
    <br>五、责任免除
    趣批发平台含有到其他平台的链接。趣批发平台对其他站的隐私保护措施不负任何责任。我们可能在任何需要的时候增加商业伙伴或共用品牌的平台。
    趣批发平台有相应的安全措施来确保我们掌握的信息不丢失，不被滥用和变造。这些安全措施包括向其它服务器备份数据和对用户密码加密。尽管我们有这些安全措施，但请注意在互联网上不存在“完善的安全措施”。
    您提供发布的供应或采购的物品信息或报价将被显示在公共区域，对任何用户来说是公开的。请注意所有在这些场所公开的信息会成为公共信息，请您在决定公布您的个人信息前仔细考虑。
    随着趣批发平台服务范围的扩大，我们会随时更新我们的隐私声明，您同意受任何该等修订的约束。我们欢迎您随时回来查看本声明。
    <div class="xy_bodyHead">法 律 声 明</div>
    在访问和使用趣批发平台前请务必阅读本声明并保证完全理解本声明所载明的一切条款，若您对条款存在异议可与趣批发平台客服联系。若您不认可本声明中的条款，可以选择立即停止使用趣批发平台的所有服务。一旦您访问、使用了趣批发平台，我们将视为您同意并接受了本声明的所有内容。
    <br>一、知识产权
    <br>（一）知识产权归属
    <br>除非稻趣公司另行声明，趣批发平台内的所有技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权及相关权利，均归稻趣公司或其关联公司所有，未经稻趣公司许可，任何人不得擅自使用。
    <br>趣批发平台的Logo，“稻趣公司”等文字、图形及其组合，以及趣批发平台的其他标识均为稻趣公司及其关联公司的商标，未经稻趣公司书面授权，任何人不得以任何方式展示、使用或做其他处理，也不得向他人表明您有权展示、使用或作其他处理。
    <br>（二）知识产权责任限制
    <br>趣批发平台上关于用户或他们的产品的信息（包括但不限于公司名称、联系人及联络信息，产品的描述和说明，相关图片、视讯等）均由用户自行提供，用户依法应对其提供的任何信息承担全部责任。稻趣公司对上述信息的准确性、完整性、合法性或真实性均不承担任何责任。稻趣公司对任何使用或提供趣批发平台信息的商业活动及其风险不承担任何责任。
    <br>对任何包含于或经由本平台，或从本平台链接、下载，或从任何与本平台有关信息服务所获得的信息、资料或广告，目的是为公众提供资讯，服务社会公众，不声明也不保证其内容的有效性、正确性或可靠性。
    <br>第三方通过本平台表述的任何意见均属于第三方意见，并不代表趣批发平台的意见，稻趣公司对此不承担责任。
    <br>（三）知识产权保护
    <br>稻趣公司尊重知识产权，反对侵权盗版行为。
    <br>趣批发平台在建设中引用了互联网上的一些资源并对有明确来源的注明了出处，转载是出于传递更多信息之目的，其版权归原作者及平台所有，如第三方从本平台下载使用，必须保留本平台注明的“稿件来源”，并自负版权等法律责任。对于不当转载或引用本平台内容而引起的民事纷争、行政处理或其他损失，稻趣公司不承担责任。如果您对于我们的使用产生任何的问题，请通知我们。
    <br>任何单位或个人认为本平台信息可能涉嫌侵犯其合法权益，应当及时向我们书面反馈，并提供身份证明、权属证明及详细侵权情况证明，我们在收到上述法律文件后，将会及时处理。
    <br>二、免责声明
    <br>趣批发平台在此特别声明对如下事宜不承担任何法律责任：
    <br>1、用户明确同意其使用趣批发平台网络服务所存在的风险将完全由其自己承担；因其使用趣批发平台服务而产生的一切后果也由其自己承担，趣批发平台对用户不承担任何责任。
    <br>2、趣批发平台不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。
    <br>3、如因系统维护或升级的需要而需要暂停网络服务，趣批发平台将尽可能提前在趣批发平台相关网页进行通告。
    <br>4、如发生下列任何一种情形，趣批发平台有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户：
    <br>(1) 用户提供的个人资料不真实；
    <br>(2) 用户违反本平台规定的使用规则；
    <br>(3) 由于用户行为对趣批发平台造成了损失。
    <br>5、除上述情形外，趣批发平台同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，趣批发平台无需对用户或任何其他服务供应商承担任何责任。
    <br>用户在使用趣批发平台网络服务过程中，必须遵循以下原则：
    <br>(1) 遵守中华人民共和国有关的法律和法规；
    <br>(2) 遵守所有与网络服务有关的网络协议、规定和程序；
    <br>(3) 不得利用趣批发平台网络服务进行任何可能对趣批发平台的正常运转造成不利影响的行为；
    <br>(4) 不得利用趣批发平台网络服务系统传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；
    <br>(5) 不得利用趣批发平台以任何方式散布和传播反动、色情或其他违反国家法律的信息，不能传输任何教唆他人构成犯罪行为的资料，不能传输助长国内不利条件和涉及国家安全的资料，不能传输任何不符合当地法规、国家法律和国际法律的资料；
    <br>(6) 如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告趣批发平台。
    <br>6、趣批发平台，包括其所用的内容，功能以及提供的信息，都是在"如页面所示"的基础上被提供的没有任何形式的声明及保证。趣批发平台清楚地表明它将对在上所出售的物品不承担任何担保责任。
    <br>7、在任何情况下，本平台都不会对由于用户不会使用平台及其提供的内容，功用而引起直接的或间接的、特殊的或偶然的或已成事实的损失负有法律责任，这包括但不局限于：收入或预算利润的减少、丢失的生意及销售，即使平台或其授权代表已被通知将会产生类似的损失。因此平台权利范围内不允许存在为偶然的或已成事实的损失而承担债务限制或排除。此上限制对某些使用来说是不适用的。


</div>
