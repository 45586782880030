<script>
    import QRCode from 'qrcode';
    import { onMount } from 'svelte'
    export let value = 'test';
    export let width = '100';
    let el;
    onMount(() => {
        createQRCode();
    })
    function createQRCode () {
        QRCode.toCanvas(el, value, {
          width: width
        }, (error) => {
            if (error) {
                console.log(error)
            }
        })
    }
</script>

<canvas bind:this={el}/>
